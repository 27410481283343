<template>
  <div class="main">
    <div class="code-container">
      <codemirror
        ref="chartOption"
        v-model="mirrorCode"
        :options="cmOptions"
        @changes="mirrorCodeChange">
      </codemirror>
    </div>
    <div class="dialog-footer">
      <el-dropdown trigger="hover" class="mr10" v-if="versionList&&versionList.length>0">
        <el-button type="primary" plain size="small">版本回溯<i class="el-icon-arrow-down el-icon--right"></i></el-button>
        <el-dropdown-menu slot="dropdown">
          <template v-for="(item, index) in versionList">
            <el-dropdown-item :key="index">{{item.glueRemark}}</el-dropdown-item>
          </template>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button type="primary" @click="submit" size="small" icon="el-icon-check">提交 </el-button>
      <el-button type="danger" @click="close" size="small" icon="el-icon-close">关闭</el-button>
    </div>
    <el-dialog
      title="保存"
      :visible.sync="dialogVisible"
      width="30%"
      :append-to-body="true">
      <el-form :model="form" :rules="rulesForm" ref="form">
        <el-form-item label="源码备注" label-width="80px" prop="glueRemark">
          <el-input placeholder="请输入源码备注" v-model="form.glueRemark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer pb20">
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { codemirror } from 'vue-codemirror';
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';
import 'codemirror/lib/codemirror.css';
// 主题样式
import 'codemirror/theme/base16-light.css';
import 'codemirror/theme/eclipse.css';
import 'codemirror/theme/darcula.css';
import 'codemirror/theme/blackboard.css';

require('codemirror/mode/clike/clike.js');
require('codemirror/addon/edit/closebrackets.js');
// require('codemirror/addon/fold/foldcode.js');
// require('codemirror/addon/fold/foldgutter.js');
// require('codemirror/addon/fold/brace-fold.js');
// require('codemirror/addon/fold/xml-fold.js');
// require('codemirror/addon/fold/indent-fold.js');
// require('codemirror/addon/fold/markdown-fold.js');
// require('codemirror/addon/fold/comment-fold.js');

export default {
  name: 'GuleIde',
  extends: Form,
  components: {
    codemirror,
  },
  data() {
    return {
      queryData: {},
      mirrorCode: '',
      cmOptions: {
        tabSize: 2, // Tab键空格数
        mode: 'text/x-java', // 模式
        theme: 'base16-light', // 主题
        autofocus: true,
        lineNumbers: true, // 显示行号
        smartIndent: true, // 自动缩进
        indentWithTabs: true, // 是否tabSize 应该用N个制表符替换前N *个空格
        autoCloseBrackets: true, // 自动补全括号
        line: true,
      },
      versionList: [],
      dialogVisible: false,
      form: {
        glueRemark: '',
      },
      rulesForm: {
        glueRemark: [
          { required: true, message: '请输入源码备注', trigger: 'blur' },
        ],
      },
    };
  },
  async created() {
    const { queryData } = this.formConfig;
    this.queryData = queryData;
    this.getDetail();
    this.getVersion();
  },

  methods: {
    // 获取详情
    getDetail() {
      request.get('/job/jobinfo/query', { id: this.formConfig.id }).then((res) => {
        if (res.success) {
          const { result } = res;
          this.mirrorCode = result.glueSource || '';
        }
      });
    },
    // 获取历史版本
    getVersion() {
      request.post('/job/jobcode/getLogGlue', { jobCode: this.formConfig.id }).then((res) => {
        if (res.success) {
          const { result } = res;
          this.versionList = result;
        }
      });
    },
    // 编辑器变化
    mirrorCodeChange() {
      // console.log(this.mirrorCode);
    },
    // 点击提交
    submit() {
      // this.$emit('onClose');
      if (this.mirrorCode) {
        this.dialogVisible = true;
      } else {
        this.$message({
          type: 'warning',
          message: '请输入执行代码',
        });
      }
    },
    // 点击关闭
    close() {
      this.$emit('onClose');
    },
    // 备注弹窗确定
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            jobCode: this.formConfig.id,
            glueSource: this.mirrorCode,
            glueRemark: this.form.glueRemark,
          };
          request.post('/job/jobcode/save', params).then((res) => {
            if (res.success) {
              this.dialogVisible = false;
              this.$emit('onClose');
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .CodeMirror {
  height: 500px !important;
}
.mr10{
  margin-right: 10px;
}
.pb20{
  padding-bottom: 20px;
}
.time-container{
  margin: 0 auto;
  .time-list{
    font-size: 14px;
    padding-bottom: 10px;
    text-align: center;
  }
}
.dialog-footer {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 20px);
  z-index: 99;
  background-color: white;
}
</style>
