<template>
  <div>
    <div class="node-container">
      <div class="node-item" v-for="(item,index) in formConfig.data.registryList" :key="item">{{index + 1}}：{{item}}</div>
    </div>
    <div class="dialog-footer">
        <slot name="footer">
          <el-button  icon="el-icon-check" type="primary" @click="onOk">确定</el-button>
        </slot>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    formConfig: Object,
  },
  data() {
    return {

    };
  },
  methods: {
    onOk() {
      this.$emit('onClose');
    },
  },
};
</script>

<style lang="less" scoped>
  .node-container{
    .node-item{
      line-height: 40px;
    }
  }
</style>
