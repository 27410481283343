var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      { staticClass: "time-container" },
      [
        _vm._l(_vm.list, function (item, index) {
          return [
            _c("div", { key: index, staticClass: "time-list" }, [
              _vm._v(_vm._s(item)),
            ]),
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "dialog-footer" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.submit },
          },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }