var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "node-container" },
      _vm._l(_vm.formConfig.data.registryList, function (item, index) {
        return _c("div", { key: item, staticClass: "node-item" }, [
          _vm._v(_vm._s(index + 1) + "：" + _vm._s(item)),
        ])
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "dialog-footer" },
      [
        _vm._t("footer", function () {
          return [
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-check", type: "primary" },
                on: { click: _vm.onOk },
              },
              [_vm._v("确定")]
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }