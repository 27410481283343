<!--
 * @Author: yzr
 * @Date: 2020-12-09 09:47:56
 * @LastEditors: yzr
 * @LastEditTime: 2020-12-16 15:35:34
-->
<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import cronEditor from './components/cronEditor.vue';

formCreate.component('cronEditor', cronEditor);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      queryData: {},
    };
  },

  async mounted() {
    await this.getFormRule('scheduler_manage_form');
    // 执行器
    const jobGroup = this.getRule('jobGroup');
    jobGroup.restful = '/job/jobgroup/getAllJobGroup';
    jobGroup.optionsKey = { label: 'title', value: 'id' };
    // 调度类型
    const scheduleType = this.getRule('scheduleType');
    scheduleType.restful = '/job/api/getJobConfig';
    scheduleType.optionsKey = { label: 'configValue', value: 'configKey' };
    scheduleType.value = 'CRON';
    scheduleType.restfulParams = { configKey: 'scheduletype' };
    scheduleType.on.change = (value) => {
      this.scheduleTypeChange(value, true);
    };
    // // 调度值
    // const scheduleConf = this.getRule('scheduleConf');
    // scheduleConf.props.controlType = 'cronEditor';
    // 路由策略
    const executorRouteStrategy = this.getRule('executorRouteStrategy');
    executorRouteStrategy.restful = '/job/api/getJobConfig';
    executorRouteStrategy.optionsKey = { label: 'configValue', value: 'configKey' };
    executorRouteStrategy.restfulParams = { configKey: 'route' };
    // 阻塞处理策略
    const executorBlockStrategy = this.getRule('executorBlockStrategy');
    executorBlockStrategy.restful = '/job/api/getJobConfig';
    executorBlockStrategy.optionsKey = { label: 'configValue', value: 'configKey' };
    executorBlockStrategy.restfulParams = { configKey: 'blockstrategy' };
    // 路由过期策略
    const misfireStrategy = this.getRule('misfireStrategy');
    misfireStrategy.restful = '/job/api/getJobConfig';
    misfireStrategy.optionsKey = { label: 'configValue', value: 'configKey' };
    misfireStrategy.restfulParams = { configKey: 'misfirestrategy' };
    // 运行模式
    const glueType = this.getRule('glueType');
    glueType.restful = '/job/api/getJobConfig';
    glueType.optionsKey = { label: 'configValue', value: 'configKey' };
    glueType.restfulParams = { configKey: 'gluetype' };
    glueType.on.change = this.glueTypeChange;

    const { buttonCode, queryData } = this.formConfig;
    this.scheduleTypeChange((queryData && queryData.scheduleType) || 'CRON');
    this.glueTypeChange(queryData && queryData.glueType);
    if (buttonCode === 'edit') {
      // TODO 编辑
      request
        .get('/job/jobinfo/query', {
          id: this.formConfig.id,
        })
        .then((res) => {
          if (res.success) {
            const { result } = res;
            result.scheduleConf2 = result.scheduleConf;
            this.queryData = result;
            this.setValue(result);
            this.reload();
          }
        });
    }
  },
  methods: {
    // 调度类型改变
    scheduleTypeChange(value, isChange) {
      const scheduleConf = this.getRule('scheduleConf');
      const scheduleConf2 = this.getRule('scheduleConf2');
      switch (value) {
        case 'NONE':// 无
          this.hiddenFields(true, 'scheduleConf');
          this.hiddenFields(true, 'scheduleConf2');
          break;
        case 'FIX_RATE':// 固定速率
          this.hiddenFields(false, 'scheduleConf2');
          this.hiddenFields(true, 'scheduleConf');
          scheduleConf2.validate = [{ ...scheduleConf.validate, required: true, message: '固定速度不能为空' }];
          if (isChange) {
            scheduleConf2.value = '';
          }
          break;
        case 'CRON':// CRON
          this.hiddenFields(false, 'scheduleConf');
          this.hiddenFields(true, 'scheduleConf2');
          scheduleConf.title = 'Cron';
          scheduleConf.props.controlType = 'cronEditor';
          scheduleConf.validate = [{ ...scheduleConf.validate, required: true, message: 'Cron不能为空' }];
          if (isChange) {
            scheduleConf.value = '';
          }
          break;
        default:
          break;
      }
      this.reload(this.rule);
    },
    // 运行模式改变
    glueTypeChange(value) {
      const executorHandler = this.getRule('executorHandler');
      if (value === 'BEAN') {
        this.disabled(false, 'executorHandler');
        executorHandler.validate = [{ ...executorHandler.validate, required: true, message: 'JobHandler不能为空' }];
      } else {
        this.setValue({ executorHandler: '' });
        this.disabled(true, 'executorHandler');
        executorHandler.validate = [{ ...executorHandler.validate, required: false, message: 'JobHandler不能为空' }];
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData();

      if (formData) {
        let url = '/job/jobinfo/add';
        let params = formData;
        params.childJobId = formData.childJobId && formData.childJobId !== 0 ? formData.childJobId : null;
        if (params.scheduleType === 'NONE') {
          params.scheduleConf = null;
        } else if (params.scheduleType === 'FIX_RATE') {
          params.scheduleConf = params.scheduleConf2;
        }
        if (this.formConfig && this.formConfig.buttonCode === 'edit') {
          url = '/job/jobinfo/update';
          params = { ...this.queryData, ...formData };
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('resetTree');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
