<template>
  <div class="main">
    <div class="time-container">
      <template v-for="(item, index) in list">
        <div :key="index" class="time-list">{{item}}</div>
      </template>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" @click="submit" size="small">确定</el-button>
    </div>
  </div>
</template>
<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  name: 'ExecuteTime',
  extends: Form,
  data() {
    return {
      queryData: {},
      list: [],
    };
  },
  async created() {
    const { queryData } = this.formConfig;
    this.queryData = queryData;
    this.getTimeList();
  },

  methods: {
    // 获取时间
    getTimeList() {
      const url = '/job/jobinfo/nextTriggerTime';
      const params = {
        // id: this.queryData.id,
        scheduleType: this.queryData.scheduleType,
        scheduleConf: this.queryData.scheduleConf,
      };
      request.post(url, params).then((res) => {
        if (res.success) {
          this.list = res.result || [];
        }
      });
    },
    // 点击确定
    submit() {
      this.$emit('onClose');
    },

  },
};
</script>
<style lang="less" scoped>
.time-container{
  margin: 0 auto;
  .time-list{
    font-size: 14px;
    padding-bottom: 10px;
    text-align: center;
  }
}
.dialog-footer {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 99;
  background-color: white;
}
</style>
