<template>
  <div class="main">
    <div class="remark-container">
      <div>
        <p v-html="msg"></p>
      </div>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" @click="submit" size="small">确定</el-button>
    </div>
  </div>
</template>
<script>
import Form from '../../../components/form';

export default {
  name: 'Remark',
  extends: Form,
  data() {
    return {
      msg: '',
    };
  },
  async created() {
    this.msg = this.formConfig.msg;
    // this.msg = this.msg.replace(/<br>/g, '\n');
    // console.log(this.msg);
  },

  methods: {
    // 点击确定
    submit() {
      this.$emit('onClose');
    },
  },
};
</script>
<style lang="less" scoped>
.remark-container{
  margin: 0 auto;
  font-size: 14px;
  // text-align: center;
}
.dialog-footer {
  position: absolute;
  bottom: 0;
  right: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 99;
  background-color: white;
}
</style>
