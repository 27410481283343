<script>
import TablePage from '../../../../../components/table_page';
import Remark from '../../../components/remark.vue';
import request from '../../../../../utils/request';
import * as util from '../../../../../utils/index';

export default {
  extends: TablePage,
  components: {
    Remark,
  },
  props: {
    propsObj: {},
  },
  data() {
    return {
      params: {
        jobId: this.propsObj.id,
        logStatus: '-1',
      },
    };
  },
  async created() {
    const colums = await this.getConfigList('scheduler_manage_log_table');
    colums.map((v) => {
      const rowData = v;
      if (rowData.field === 'triggerMsg') {
        rowData.type = 'html';
        rowData.formatter = () => '查看';
      }
      return rowData;
    });
    await this.getJobGroup();
    this.searchList.map((v, k) => {
      const item = v;
      if (item.field === 'jobGroup') {
        this.options.map((a) => {
          const pItem = a;
          pItem.label = pItem.title;
          pItem.value = pItem.id;
          return pItem;
        });
        this.$set(this.searchList[k].render, 'options', this.options);
        this.$forceUpdate();
      }
      return item;
    });
  },
  watch: {
    formData: {
      deep: true,
      handler(val) {
        if (val.triggerTime2) {
          this.formData.triggerTime = new Date(val.triggerTime2).getTime();
        }
      },
    },
  },
  methods: {
    // table表格
    cellClick({ row, column }) {
      if (column.property === 'triggerMsg') {
        this.formName = 'Remark';
        this.formConfig = {
          ...row,
          msg: row.triggerMsg,
        };
        this.modalConfig.title = '调度备注';
        this.modalConfig.width = '600px';
        this.openModal();
      }
    },
    // 行操作按钮显示隐藏
    clickVisible({ row }) {
      if (row.triggerCode === 200) {
        return true;
      }
      return false;
    },
    // 设置每个单元格的数据
    setColumn(row) {
      const rowValue = row;
      if (rowValue.field === 'triggerTime') {
        rowValue.formatter = (val) => {
          let value = '';
          if (val && val.cellValue) {
            value = util.getNowFormatDate(val.cellValue);
          }
          return value;
        };
      } else if (rowValue.field === 'handleTime') {
        rowValue.formatter = (val) => {
          let value = '';
          if (val && val.cellValue) {
            value = util.getNowFormatDate(val.cellValue);
          }
          return value;
        };
      }
      return rowValue;
    },
    // 点击按钮
    modalClick({ val, row }) {
      if (val.code === 'execute_log') {
        const params = {
          fromLineNum: 1,
          executorAddress: row.executorAddress,
          logId: row.id,
          triggerTime: row.triggerTime,
        };
        request.post('/job/joblog/logDetailCat', params).then((res) => {
          if (res.success) {
            this.formName = 'Remark';
            this.formConfig = {
              msg: res.result.logContent,
            };
            this.modalConfig.title = '执行备注';
            this.modalConfig.width = '600px';
            this.openModal();
            // this.$message({
            //   type: 'success',
            //   message: '执行日志成功',
            // });
          }
        });
      } else if (val.code === 'terminate_task') {
        const params = {
          id: row.id,
        };
        request.post('/job/joblog/logKill', params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '终止任务成功',
            });
          }
        });
      }
    },
    // 获取执行器列表
    async getJobGroup() {
      const resData = await request.post('/job/jobgroup/getAllJobGroup', { });
      this.options = resData.result;
    },
  },
};
</script>
