var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "div",
        { staticClass: "code-container" },
        [
          _c("codemirror", {
            ref: "chartOption",
            attrs: { options: _vm.cmOptions },
            on: { changes: _vm.mirrorCodeChange },
            model: {
              value: _vm.mirrorCode,
              callback: function ($$v) {
                _vm.mirrorCode = $$v
              },
              expression: "mirrorCode",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _vm.versionList && _vm.versionList.length > 0
            ? _c(
                "el-dropdown",
                { staticClass: "mr10", attrs: { trigger: "hover" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", plain: "", size: "small" } },
                    [
                      _vm._v("版本回溯"),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _vm._l(_vm.versionList, function (item, index) {
                        return [
                          _c("el-dropdown-item", { key: index }, [
                            _vm._v(_vm._s(item.glueRemark)),
                          ]),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", icon: "el-icon-check" },
              on: { click: _vm.submit },
            },
            [_vm._v("提交 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-close" },
              on: { click: _vm.close },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "保存",
            visible: _vm.dialogVisible,
            width: "30%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rulesForm } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "源码备注",
                    "label-width": "80px",
                    prop: "glueRemark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入源码备注" },
                    model: {
                      value: _vm.form.glueRemark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "glueRemark", $$v)
                      },
                      expression: "form.glueRemark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer pb20",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("form")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }