<script>
import TablePage from '../../../../../components/table_page';
import Form from '../form';
import Modal from '../../../../../components/modal';
import request from '../../../../../utils/request';

import ExecuteOnce from '../execute/execute_once.vue';
import ExecuteTime from '../execute/execute_time.vue';
import ExecuteLog from '../execute/execute_log.vue';
import GuleIde from '../code/glue_ide.vue';
import GroupNode from '../group_node/group_node.vue';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
    ExecuteOnce,
    ExecuteTime,
    ExecuteLog,
    GuleIde,
    GroupNode,
  },
  data() {
    return {
      params: {
        code: '',
      },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
        width: '1000px',
      },
      configs: {
        formConfig: {
          item: [
            {
              field: 'jobGroup',
              title: '执行器',
              type: 'select',
              apiUrl: '/job/jobgroup/getAllJobGroup',
              optionsKey: {
                label: 'title',
                value: 'id',
              },
            },
          ],
        },
      },
    };
  },
  props: {
    treeCode: String,
  },
  watch: {
    treeCode(val) {
      this.params.code = val;
      this.getList();
    },
  },
  created() {
    this.getConfigList('scheduler_manage_table');
  },
  methods: {
    beforeGetList() {
      if (!this.searchFormData.jobGroup) {
        const jobGroup = this.searchList[0] && this.searchList[0].render && this.searchList[0].render.options && this.searchList[0].render.options[0].value;
        this.formData.jobGroup = jobGroup;
        this.searchFormData.jobGroup = jobGroup;
      }
      return true;
    },
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },
    modalClick({ val, row }) {
      this.formName = 'Form';
      console.log(val);
      this.formConfig = {};
      let urls = '';
      let text = '';
      if (val.code === 'edit') {
        this.formConfig = {
          ...val,
          ...row,
          queryData: row,
        };
        console.log('edit', this.formConfig);
        this.modalConfig.title = '编辑';
        this.modalConfig.width = '1000px';
        this.openFull();
      } else if (val.code === 'enable') {
        urls = '/mdm/mdmCusOrgController/enAbleBatch';
        text = '启用';
        this.disableClick(urls, text);
      } else if (val.code === 'disable') {
        urls = '/mdm/mdmCusOrgController/disAbleBatch';
        text = '禁用';
        this.disableClick(urls, text);
        // } else if (val.code === 'position_maintenance') {
        //   this.formName = 'PositionTable';
        //   this.modalConfig.title = '';
        //   this.openFull();
      } else if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.modalConfig.width = '1000px';
        this.openFull();
      } else if (val.code === 'execute_once') {
        this.formConfig = {
          ...val,
          ...row,
          queryData: row,
        };
        this.formName = 'ExecuteOnce';
        this.propsObjInData = { ...row, btnCode: 'execute_once' };
        this.modalConfig.title = '执行一次';
        this.modalConfig.width = '800px';
        this.openModal();
      } else if (val.code === 'next_execute_time') {
        this.formConfig = {
          ...val,
          ...row,
          queryData: row,
        };
        this.formName = 'ExecuteTime';
        this.propsObjInData = { ...row, btnCode: 'next_execute_time' };
        this.modalConfig.title = '下次执行时间';
        this.modalConfig.width = '600px';
        this.openModal();
      } else if (val.code === 'view_log') {
        this.formName = 'ExecuteLog';
        this.propsObjInData = { ...row, btnCode: 'view_log' };
        this.modalConfig.title = '查看日志';
        this.openFull();
      } else if (val.code === 'glue_ide') {
        this.formConfig = {
          ...val,
          ...row,
          queryData: row,
        };
        this.formName = 'GuleIde';
        this.propsObjInData = { ...row, btnCode: 'glue_ide' };
        this.modalConfig.title = 'GULE IDE';
        this.openFull();
      } else if (val.code === 'register_node') {
        request.post('/job/jobgroup/loadById', { id: this.searchFormData.jobGroup }).then((res) => {
          this.formName = 'GroupNode';
          this.modalConfig.title = '注册节点';
          this.modalConfig.width = '600px';
          this.formConfig.data = res.result;
          this.openModal();
        });
      }
    },
    disableClick(urls, text) {
      const datas = [];
      this.selectRow.forEach((v) => {
        datas.push(v.id);
      });
      this.$confirm(`确定${text}该终端？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        request.post(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.getList();
          }
        });
      });
    },
  },
};
</script>
