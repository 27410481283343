<template>
  <div>
    <div v-if="controlType==='cronEditor'" id="cronEditor">
        <div class="box" @click="showDialog">
            <el-input v-model="cron" readonly placeholder ="Cron表达式" class="inp">
              <i class="el-icon-search el-input__icon" slot="suffix" @click="showDialog"> </i>
            </el-input>
        </div>
        <el-dialog title="生成 cron" :visible.sync="showCron" append-to-body>
            <vcrontab @hide="showCron=false" @fill="crontabFill" :expression="expression"></vcrontab>
        </el-dialog>
    </div>
    <div v-else>
        <el-input v-model="input" @input="speedChange" placeholder ="请输入固定速度" class="inp"/>
    </div>
  </div>
</template>

<script>
import vcrontab from 'vcrontab';

export default {
  components: {
    vcrontab,
  },
  props: {
    value: String,
    controlType: String,
  },
  data() {
    return {
      input: '',
      cron: '',
      expression: '',
      showCron: false,
    };
  },
  watch: {
    controlType() {
      console.log('controlType', this.controlType);
    },
    value() {
      this.cron = this.value;
    },
  },
  methods: {
    crontabFill(value) {
      // 确定后回传的值
      this.cron = value;
      this.$emit('input', value);
    },
    showDialog() {
      this.expression = this.cron;// 传入的 cron 表达式，可以反解析到 UI 上
      this.showCron = true;
    },
    speedChange(value) {
      this.$emit('input', value);
    },
  },
};
</script>
<style lang="less" scoped>
 .box {
   cursor: pointer;
 }
 /deep/ .el-input__inner{
   cursor: pointer;
 }
</style>
