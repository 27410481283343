<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  components: {
  },
  data() {
    return {
      queryData: {},
    };
  },
  async created() {
    await this.getFormRule('scheduler_manage_execute_form');
    const { queryData } = this.formConfig;
    this.queryData = queryData;
  },

  methods: {
    // 提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        const url = '/job/jobinfo/trigger';
        const params = formData;
        params.id = this.queryData.id;
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('resetTree');
          }
        });
      }
    },
  },
};
</script>
